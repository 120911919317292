import React, { useState, useEffect } from 'react';
import './App.css';
import ImageUploader from './ImageUploader';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function App() {
  const [images, setImages] = useState([]);
  const [carId, setCarId] = useState('');
  const [companyId, setCompanyId] = useState('');
  const [pontoDeVenda, setPontoDeVenda] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const carId = urlParams.get('car_id');
    const companyId = urlParams.get('empresa_id');
    const pontoDeVenda = urlParams.get('ponto_de_venda');

    setCarId(carId);
    setCompanyId(companyId);
    setPontoDeVenda(pontoDeVenda);

    console.log(`URL parameters: car_id=${carId}, empresa_id=${companyId}, ponto_de_venda=${pontoDeVenda}`);

    if (carId && companyId) {
      fetch(`https://test.almaraoavista.pt/get_images?empresa_id=${companyId}&car_id=${carId}`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          return response.json();
        })
        .then(data => {
          console.log(`Fetched images: ${JSON.stringify(data)}`);
          const existingImages = data.map((image, index) => ({
            id: `image-${Date.now()}-${index}`,
            order: image.order,
            url: image.url,
            isNew: false, // Indicate this image is from the backend
          }));
          setImages(existingImages);
        })
        .catch(error => console.error('Error fetching images:', error));
    }
  }, []);

  const handleImageSelection = (newImages) => {
    const newImageObjects = newImages.map((file, index) => ({
      id: `image-${Date.now()}-${index}`,
      file,
      url: URL.createObjectURL(file),
      isNew: true, // Indicate this is a new image
    }));
    setImages([...images, ...newImageObjects]);
  };

  const handleSaveOrder = async () => {
    setIsSaving(true);
    try {
      const formData = new FormData();
      formData.append('empresa', companyId);
      formData.append('car_id', carId);
      formData.append('ponto_venda', pontoDeVenda);

      const newImages = images.filter(image => image.isNew);
      newImages.forEach((image) => {
        if (image.file) {
          formData.append('files', image.file);
        }
      });

      if (newImages.length > 0) {
        const response = await fetch('https://test.almaraoavista.pt/upload', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Failed to upload images');
        }
      }

      const payload = {
        images: images.map((image, index) => ({
          url: image.isNew ? `https://stand-gest.s3.amazonaws.com/Grupo Rocha/Matosinhos/${carId}/${image.file.name}` : image.url,
          order: index + 1,
          empresa: companyId,
          car_id: carId,
          ponto_venda: pontoDeVenda,
        })),
        empresa_id: companyId,
        car_id: carId
      };

      console.log('Payload being sent:', payload);

      const manageResponse = await fetch('https://test.almaraoavista.pt/manage_images', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });

      if (!manageResponse.ok) {
        throw new Error('Network response was not ok');
      }

      setShowSuccessMessage(true);
      // Mark all new images as saved (i.e., not new anymore)
      setImages(images.map(image => ({ ...image, isNew: false })));

    } catch (error) {
      console.error('Error:', error);
      alert('Failed to save images. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const reorderedImages = Array.from(images);
    const [removed] = reorderedImages.splice(result.source.index, 1);
    reorderedImages.splice(result.destination.index, 0, removed);
    setImages(reorderedImages);
  };

  const handleRemoveImage = (id) => {
    setImages(images.filter((image) => image.id !== id));
  };

  return (
    <div className="App">
      <h1>Image Uploader</h1>
      <ImageUploader onImageSelection={handleImageSelection} />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="images" direction="horizontal">
          {(provided) => (
            <div
              className="image-list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {images.map((image, index) => (
                <Draggable key={image.id} draggableId={image.id} index={index}>
                  {(provided) => (
                    <div
                      className="image-item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <img src={image.url} alt={`Selected ${index}`} />
                      <span className="image-order">{index + 1}</span>
                      <button
                        className="remove-button"
                        onClick={() => handleRemoveImage(image.id)}
                      >
                        ✖
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <button onClick={handleSaveOrder} disabled={isSaving}>Save Image Order</button>

      {showSuccessMessage && (
        <div className="success-message">
          <p>Upload successful!</p>
        </div>
      )}
    </div>
  );
}

export default App;
