import React from 'react';
import './ImageUploader.css';

const ImageUploader = ({ onImageSelection }) => {
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    onImageSelection(files);
  };

  return (
    <div className="dropzone">
      <input type="file" multiple onChange={handleFileChange} />
    </div>
  );
};

export default ImageUploader;
